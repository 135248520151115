import React from 'react';
import ReactLoading from 'react-loading';
import { Grid } from '@material-ui/core';
import { Typography } from './Typography/Typography';

type LoadingType = "blank" | "balls" | "bars" | "bubbles" | "cubes" | "cylon" | "spin" | "spinningBubbles" | "spokes";

export interface LoadingProps {
  type?: LoadingType,
  color?: string,
  sizePx?: string,
  heightVh?: string
}

export const Loading: React.FC<LoadingProps> = ({
  type = 'spin',
  color = '#2A29A6',
  sizePx = '55px',
  heightVh = '80vh'
}: LoadingProps) => {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      style={{ height: heightVh }}
    >
      <ReactLoading type={type} color={color} height={sizePx} width={sizePx} />
      <Typography variant="Caption" customColor="darkGrey" style={{ marginTop: 8 }}>
        Loading...
      </Typography>
    </Grid>
  )
}
