import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

export interface A8TypographyProps extends React.HTMLAttributes<HTMLElement> {
  variant: 'Title' | 'Subheading' | 'Body' | 'Caption' | 'Overline';
  as?: any;
  color?: 'primary' | 'darkGray' | 'gray' | 'white' | 'disabled' | 'error';
  customColor?: string;
  padding?: 'dense' | 'normal' | 'large';
  fontStyle?: 'italic' | 'normal' | 'oblique' | 'inherit' | 'initial' | 'unset';
  textAlign?: 'left' | 'center' | 'right';
  display?: 'block' | 'inline';
  fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 'bold' | 'bolder' | 'lighter' | 'normal';
}

const TypographyFilter = React.forwardRef((props: A8TypographyProps, ref: any) => {
  const { fontStyle, fontWeight, variant, ...rest } = props;
  return (<span ref={ref} {...rest} />);
});


export const Typography = styled(TypographyFilter)`
  ${(p) => {
    switch (p.variant) {
      case "Title": return `
        font-family: ${theme.headerFontFamily};
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 2.2rem;
          `;
      case "Subheading": return `
        font-family: ${theme.headerFontFamily};
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.25rem;
      `;
      case "Body": return `
        font-family: ${theme.fontFamily};
        font-size: 0.9rem;
        line-height: 0.9rem;
      `;
      case "Caption": return `
        font-family: ${theme.fontFamily};
        font-size: 0.75rem;
        line-height: 1rem;
      `;
      case "Overline": return `
        font-family: ${theme.fontFamily};
        font-size: 0.75rem;
        line-height: 1rem;
        text-transform: uppercase;
        display: block;
        letter-spacing: 0.25px;
      `;
      default: return '';
    }
  }}
  ${(p) => {
    switch (p.color) {
      case "primary": return `color: ${theme.primaryColor};`;
      case "darkGray": return `color: ${theme.darkGray};`;
      case "gray": return `color: ${theme.gray};`;
      case "white": return `color: white;`;
      case "disabled": return `color: ${theme.disabledColor};`;
      case "error": return `color: ${theme.errorColor};`;
      default: return `color: ${theme.darkGray};`;
    }
  }}
  ${(p) => {
    switch (p.padding) {
      case "dense": return `padding: 4px 0px;`;
      case "normal": return `padding: 12px 0px;`;
      case "large": return `padding: 24px 0px;`;
      default: return `padding: 4px 0px;`;
    }
  }}
  ${(p) => p.customColor ? `color: ${p.customColor};` : ''}
  ${(p) => p.fontStyle ? `font-style: ${p.fontStyle};` : ''}
  ${(p) => p.display ? `display: ${p.display};` : ''}
  ${(p) => p.fontWeight ? `font-weight: ${p.fontWeight};` : ''}
  ${(p) => p.textAlign ? `
    text-align: ${p.textAlign};
    display: block;
  ` : ''}
`;
