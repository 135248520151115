import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  makeStyles,
  Grid
} from '@material-ui/core';
import { A8Button } from './Button';
import { Typography } from './Typography/Typography';

const useStyles = makeStyles({
  modalPaper: {
    padding: '35px 0 20px 0',
    width: '340px',
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: 50,
    color: 'inherit',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  dialogActions: {
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '10px 48px'
  },
  button: {
    height: '45px',
    '&:first-of-type': {
      marginBottom: '15px'
    }
  },
  noPaddingTop: {
    paddingTop: '0px !important'
  }
});

export interface DiveBackInModalProps {
  imgSrc: string,
  timestamp: number,
  requestTitle: string,
  requestSubtitle: string,
  continueRequest: () => void,
  continueRequestLater: () => void
}

export const DiveBackInModal: React.FC<DiveBackInModalProps> = ({
  imgSrc,
  timestamp,
  requestTitle,
  requestSubtitle,
  continueRequest,
  continueRequestLater
}) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const close = () => setOpen(false);

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      classes={{
        paper: classes.modalPaper
      }}
    >
      <DialogTitle>
        <Typography variant="Title">Dive</Typography>
        <Typography
          variant="Title"
          fontWeight="normal"
          fontStyle="italic"
        >&nbsp;back in?</Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography
            variant="Caption"
            display="block"
            padding="large"
            className={classes.noPaddingTop}
          >
            It looks like your session timed out before you submitted your request for:
          </Typography>

          <Grid container >
            <Grid item>
              <img src={imgSrc} style={{  width: 72, height: 72 }} />
            </Grid>
            <Grid item style={{ marginLeft: 12, width: 190 }}>
              <Typography
                variant="Caption"
                color="primary"
                display="block"
                fontWeight="bold"
                className={classes.noPaddingTop}
              >
                { requestTitle }
              </Typography>

              <Typography
                variant="Caption"
                color="darkGray"
                display="block"
                className={classes.noPaddingTop}
                style={{ opacity: '65%' }}
              >
                { requestSubtitle }
              </Typography>

              <Typography
                variant="Caption"
                color="primary"
                fontStyle="italic" style={{ opacity: '65%' }}
              >
                Created: {
                  new Date(timestamp).toLocaleString('en-US', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })
                }
              </Typography>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogActions disableSpacing classes={{ root: classes.dialogActions }}>
        <A8Button
          variant="contained"
          size="small"
          classes={{ root: classes.button }}
          onClick={() => {
            continueRequest();
            close();
          }}
          data-meta-action="btnAutosaveContinueRequest"
        >
          Continue Editing
        </A8Button>

        <A8Button
          variant="outlined"
          size="small"
          classes={{ root: classes.button }}
          onClick={() => {
            continueRequestLater();
            close();
          }}
          data-meta-action="btnAutosaveContinueRequestLater"
        >
          Not now
        </A8Button>
      </DialogActions>
    </Dialog>
  );
};
